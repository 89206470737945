<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="pricing_container">
			<div class="pricing_title">
				<h1>백신 FC-Anti Virus 요금 계산기</h1>
				<p>
					원하는 플랫폼을 선택하고 요금 계산기로 대략의 요금을 측정해 보세요<br />
					<span>※월 사용량을 기준으로 결제 됩니다※</span>
				</p>
			</div>
			<!--pricing_title-->
			<div id="pricing_form">
				<div class="form_pricing_group">
					<label for="input-3">PE 포맷 기반 탐지 알고리즘</label>
					<input
						type="text"
						v-model="option1"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
				<div class="form_pricing_group">
					<label for="input-3">이미지 기반 탐지 알고리즘</label>
					<input
						type="text"
						v-model="option2"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
				<div class="form_pricing_group">
					<label for="input-3">통합 백신 알고리즘</label>
					<input
						type="text"
						v-model="option3"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
				<!--form__group-->
				<div class="form_pricing_group_button">
					<button @click="showResult">요금 결과</button>
				</div>
				<!--button-->
			</div>
			<!--pricing_form end-->
			<div id="pricing_result_container">
				<div class="title_content">
					<div class="form_result_title">
						<p>요금표시 <i class="fas fa-caret-down"></i></p>
					</div>
					<div class="form_result_content">
						<div class="form_result_box form_vaccine_result_box">
							<label>PE 포맷 기반 탐지 알고리즘 : </label>
							<span>{{ this.result1 }}</span>
						</div>
						<div class="form_result_box form_vaccine_result_box">
							<label>이미지 기반 탐지 알고리즘 : </label>
							<span>{{ this.result2 }}</span>
						</div>
						<div class="form_result_box form_vaccine_result_box">
							<label>통합 백신 알고리즘 : </label>
							<span>{{ this.result3 }}</span>
						</div>
					</div>
					<!--form_result_content-->
				</div>
				<div class="form_result_pricing">
					<div class="form_result_box">
						<label>총 요금 예상 비용</label>
						<span>{{ this.pricing }}</span>
					</div>
					<div class="pricing_button_box">
						<button class="file">파일 다운로드</button>
						<button class="pay">결제하기</button>
					</div>
				</div>
				<!--form_result_pricing-->
			</div>
			<!--pricing_result_container end-->
		</div>
		<!--pricing_container-->
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			menuKr: '백신 서비스 요금',
			menuEn: 'pricing',
			option1: '',
			option2: '',
			option3: '',
			result1: '',
			result2: '',
			result3: '',
			pricing: '',
		};
	},
	methods: {
		showResult() {
			{
				this.result1 = this.option1;
				this.result2 = this.option2;
				this.result3 = this.option3;
				this.pricing = (this.option1 + this.option2 + this.option3) * 300 * 30;
			}
		},
	},
};
</script>

<style></style>
